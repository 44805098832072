import React, { useMemo } from "react";

import { readState } from "@/__main__/app-state.mjs";
import type { ApexMatch } from "@/data-models/apex-match.mjs";
import type { HitStats } from "@/shared-fps/hit-stats-types.mjs";
import { default as HitStatsShared } from "@/shared-fps/HitStats.jsx";
import { filterErrState } from "@/util/eval-state.mjs";
import { sanitizeNumber } from "@/util/helpers.mjs";
import isObject from "@/util/is-object.mjs";
import { useSnapshot } from "@/util/use-snapshot.mjs";

// Prevent more than 20 matches for calculations because this is re-calculated per match until we reach the threshold
const MAX_MATCH_CALC_THRESHOLD = 20;

export default function MatchTileHits({
  profileId,
  match,
}: {
  profileId: string;
  match: ApexMatch;
}) {
  const state = useSnapshot(readState);
  // Calculating current match stats
  const player = useMemo(
    () => match.playerMatchStats.find((i) => i.platformId === profileId),
    [match.playerMatchStats, profileId],
  );
  const matchStats = useMemo(() => getHitStatsByMatch(player), [player]);
  // Calculating all match stats
  const matches = useMemo(
    () =>
      Object.values(state.apex.matches)
        .filter(
          (i) =>
            // Validate the match
            filterErrState(i) &&
            // Player is within the match + the same game mode as the current match
            i.playerMatchStats.find((j) => j.platformId === profileId) &&
            i.gameMode === match.gameMode,
        )
        .sort((a, b) => b.gameStartedAt - a.gameStartedAt)
        .slice(0, MAX_MATCH_CALC_THRESHOLD) as Array<ApexMatch>,
    [match.gameMode, profileId, state.apex.matches],
  );
  const hitStatsAvg = useMemo(
    () =>
      depthIntAvg(
        matches.reduce(
          (acc, match) =>
            mergeIntsAndAvg(
              acc,
              getHitStatsByMatch(
                match.playerMatchStats.find((i) => i.platformId === profileId),
              ),
            ),
          Object.assign(getHitStatsByMatch(), { matches: matches.length }),
        ),
        matches.length,
      ),
    [matches, profileId],
  );
  return (
    <HitStatsShared
      matchStats={matchStats}
      comparisonStats={hitStatsAvg}
      // This JSX file needs to be converted to a TSX file in the future
      // it was created 1.5 years ago and needs to be updated
      division={undefined}
      hasDmgPerRound={false}
      hasLegshots={false}
      hideHeadshotData={false}
      splitTorso={false}
      table={null}
    />
  );
}

function getHitStatsByMatch(
  player?: ApexMatch["playerMatchStats"][0],
): HitStats {
  const headshots = player?.headshots ?? 0;
  return {
    avgDamagePerRound: player?.damage_done ?? 0,
    weaponHits: {
      headshots: headshots,
      bodyshots: (player?.hits ?? 0) - headshots,
      legshots: 0,
      chestshots: 0,
      stomachshots: 0,
    },
  };
}

// Depth recursive object accumulator for numeric values + averaging after accumulation
function mergeIntsAndAvg(source, target) {
  // Invalidate improper usage of source or target,
  // These objects must be identical in shape, or atleast target must match the shape of the source
  if (!isObject(source) || !isObject(target)) return source;
  for (const key in target) {
    const tVal = target[key];
    const sVal = source[key];
    const vals = [sVal, tVal];
    if (vals.every(isObject)) {
      mergeIntsAndAvg(...(vals as FixedLengthArray<HitStats, 2>));
      continue;
    }
    if (vals.some((i) => typeof i !== "number")) continue;
    source[key] += tVal;
  }
  return source;
}

function depthIntAvg(source, observation: number) {
  if (!observation) return source;
  for (const key in source) {
    if (key === "matches") continue;
    const value = source[key];
    if (isObject(value)) {
      depthIntAvg(value, observation);
      continue;
    }
    if (typeof value !== "number") continue;
    source[key] = Math.round(sanitizeNumber(value / observation));
  }
  return source;
}
